/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../firebase/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { signInUser } from "../../redux/actions";

import Page from "../../components/containers/Page";
import Input from "../../components/templates/Input";
import ModalInfo from "../../components/modal/ModalInfo";
// import LoaderModal from "../../components/modal/LoaderModal";

const SignUp = ({ signInUser }) => {
  // ---- !! -- Vars -- !! ---- //
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [infoModalData, setInfoModalData] = useState("");
  const [signUpData, setSignUpData] = useState({
    email: "",
    password: "",
    password2: "",
    passwordMatch: false,
  });
  const { email, password, password2, passwordMatch } = signUpData;
  const [passwordContain, setPasswordContain] = useState({
    number: false,
    upperCase: false,
    longEnough: false,
  });
  const { number, upperCase, longEnough } = passwordContain;
  let navigate = useNavigate();

  useEffect(() => {
    verifyPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    matchPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, password2]);

  const handleSignUp = (event) => {
    event.preventDefault();
    if (number && upperCase && longEnough && passwordMatch) {
      setOpenLoader(true);
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          sendEmailVerification(userCredential.user);
          signInUser(email);
          navigate("/");
        })
        .catch((error) => {
          setOpenLoader(false);
          let message = error.message;
          message = message.slice(
            message.indexOf("/") + 1,
            message.indexOf(")")
          );
          message = message.replaceAll("-", " ");
          setInfoModalData(message);
          setOpenInfoModal(true);
        });
    }
  };

  const handleChangeData = (event) => {
    setSignUpData({
      ...signUpData,
      [event.target.id]: event.target.value,
    });
  };

  const verifyPassword = () => {
    let numberIn = false;
    let upperCaseIn = false;
    let isLongEnough = false;
    if (password) {
      for (let i = 0; i < password.length; i++) {
        const charCode = password.charCodeAt(i);
        if (charCode >= 48 && charCode <= 57) {
          numberIn = true;
        } else if (charCode >= 65 && charCode <= 90) {
          upperCaseIn = true;
        }
        if (password.length >= 8) {
          isLongEnough = true;
        }
      }
    }
    setPasswordContain({
      number: numberIn,
      upperCase: upperCaseIn,
      longEnough: isLongEnough,
    });
  };

  const matchPassword = () => {
    let doPasswordMatch = false;
    if (password === password2) doPasswordMatch = true;
    setSignUpData({
      ...signUpData,
      passwordMatch: doPasswordMatch,
    });
  };

  const handleInfoModal = () => {
    setOpenInfoModal(!openInfoModal);
  };

  return (
    <Page classname="sign-up" goBack={true}>
      <section className="sign-up__container">
        <h2>Sing Up</h2>
        <form className="sign-up__form" onSubmit={handleSignUp}>
          <Input
            id="email"
            type="Email"
            placeHolder="Type your Email"
            label="Email"
            onChange={handleChangeData}
          />
          <Input
            id="password"
            type="Password"
            placeHolder="Type your Password"
            label="Password"
            onChange={handleChangeData}
          />
          <div className="sign-up__">
            <p>
              <b>Make sure your password:</b>
            </p>
            <p className={number ? "green-text" : "red-text"}>
              Contains a number.
            </p>
            <p className={upperCase ? "green-text" : "red-text"}>
              Contains a capital letter.
            </p>
            <p className={longEnough ? "green-text" : "red-text"}>
              It has at least 8 characters.
            </p>
          </div>
          <Input
            id="password2"
            type="Password"
            placeHolder="Type your Password again"
            label="Verify Password"
            onChange={handleChangeData}
          />
          {password &&
            (passwordMatch ? (
              <p className="green-text">Password Match</p>
            ) : (
              <p className="red-text">Password do not Match</p>
            ))}
          <button>Sign Up</button>
        </form>
        <div className="sign-up__links">
          <Link to="/sign-in">
            Already have an Account? <br />
            Sign in.
          </Link>
        </div>
      </section>
      <ModalInfo
        isOpen={openInfoModal}
        info={infoModalData}
        handleClose={handleInfoModal}
      />
      {/* <LoaderModal isOpen={openLoader} /> */}
    </Page>
  );
};
const mapDispatchToProps = {
  signInUser,
};
export default connect(null, mapDispatchToProps)(SignUp);
