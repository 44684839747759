import React from "react";
import { createPortal } from "react-dom";
import Loader from "../templates/Loader";

const ModalLoader = ({ isOpen }) => {
  if (!isOpen) return null;
  return createPortal(
    <div className="modal-loader">
      <Loader />
    </div>,
    document.getElementById("modal")
  );
};

export default ModalLoader;
