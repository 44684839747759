const CompressImage = (image, name, maxSize = 1080, outputType = "jpeg") => {
  const canvas = document.createElement("canvas");
  const imageWidth = image.width;
  const imageHeight = image.height;
  const maxImageSize = maxSize;
  let scaleSize = 0;

  if (imageHeight > imageWidth) {
    scaleSize = maxImageSize / imageHeight;
    canvas.height = maxImageSize;
    canvas.width = imageWidth * scaleSize;
  } else {
    scaleSize = maxImageSize / imageWidth;
    canvas.width = maxImageSize;
    canvas.height = imageHeight * scaleSize;
  }

  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  const srcEncoded = ctx.canvas.toDataURL(`image/${outputType}`, 0.5);

  const imageOutput = {
    src: srcEncoded,
    width: canvas.width,
    height: canvas.height,
    name: name,
  };

  return imageOutput;
};

export default CompressImage;
