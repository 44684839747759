import React from "react";
import { createPortal } from "react-dom";

const Modal = ({ isOpen, handleClose, children }) => {
  if (!isOpen) return null;
  return createPortal(
    <div className="Modal">
      <div className="Modal__container">
        <button className="Modal__close-button" onClick={handleClose}>
          <i className="icon-cross"></i>
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default Modal;
