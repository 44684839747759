import React, { useEffect } from "react";

const Page = ({ classname, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <main className={`page ${classname}`}>{children}</main>;
};

export default Page;
