import jsPDF from "jspdf";

const SaveFile = (imagesList, name) => {
  // const { name } = fileData;
  // A4 = 210 * 297 mm

  const file = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // let count = 1;

  for (let i = 0; i < imagesList.length; i++) {
    const imagesArray = imagesList[i];

    let totalHeight = 0;

    // Calculando la altura total
    for (let h = 0; h < imagesArray.length; h++) {
      const imageObj = imagesArray[h];
      // const imageWidth = imageObj.width;
      // console.log(imageWidth);

      let imageHeightInCm = imageObj.height / 150 / 0.393701;
      imageHeightInCm = parseFloat((imageHeightInCm * 10).toFixed(1));

      totalHeight += imageHeightInCm;
    }

    const marginTop = (297 - totalHeight) / (imagesArray.length + 1);

    let yPosition = 0;

    for (let e = 0; e < imagesArray.length; e++) {
      const imageObj = imagesArray[e];

      // Height In CM
      let imageHeightInCm = imageObj.height / 150 / 0.393701;
      imageHeightInCm = parseFloat((imageHeightInCm * 10).toFixed(1));
      // Width In CM
      let imageWidthInCm = imageObj.width / 150 / 0.393701;
      imageWidthInCm = parseFloat((imageWidthInCm * 10).toFixed(1));

      const marginLeft = (210 - imageWidthInCm) / 2;

      yPosition += marginTop;

      file.addImage(
        imageObj.src,
        "JPEG",
        marginLeft,
        yPosition,
        imageWidthInCm,
        imageHeightInCm,
        imageObj.name,
        "SLOW",
        0
      );

      yPosition += imageHeightInCm;
    }
    if (i !== imagesList.length - 1) {
      file.addPage();
    }
  }

  file.save(`${name ? name : "ImagePDF"}.pdf`);
};

export default SaveFile;

// Circle
// file.saveGraphicsState();
// file.setGState(new file.GState({ opacity: 0.5 }));
// file
//   .setFillColor("#593d88")
//   .circle(
//     marginLeft / 2,
//     yPosition + marginLeft / 2,
//     marginLeft / 2,
//     "F"
//   );
// file.restoreGraphicsState();

// // Text
// file
//   .setFont("helvetica", "bold")
//   .text(`${count}`, marginLeft / 2, yPosition + marginLeft / 2);
// ++count;
