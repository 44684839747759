import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../firebase/firebaseConfig";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { signInUser } from "../../redux/actions";

// Components
import Page from "../../components/containers/Page";
import Input from "../../components/templates/Input";
import ModalInfo from "../../components/modal/ModalInfo";
import ModalResetPw from "../../components/modal/ModalResetPw";
// import LoaderModal from "../../components/modal/LoaderModal";

const SignIn = ({ signInUser }) => {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  // const [openLoader, setOpenLoader] = useState(false);
  const [infoModal, setInfoModal] = useState("");
  const [openResetPwModal, setOpenResetPwModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = signInData;
  let navigate = useNavigate();

  const setData = (event) => {
    setSignInData({
      ...signInData,
      [event.target.id]: event.target.value,
    });
  };

  // Sign In
  const handleSignIn = (event) => {
    event.preventDefault();
    // setOpenLoader(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        signInUser(email);
        navigate("/");
      })
      .catch((error) => {
        // setOpenLoader(false);
        let message = error.message;
        message = message.slice(message.indexOf("/") + 1, message.indexOf(")"));
        message = message.replaceAll("-", " ");
        setInfoModal(message);
        setOpenInfoModal(true);
      });
  };

  // Set Email to Reset Password
  const handleSetResetData = (event) => {
    setResetEmail(event.target.value);
  };
  // Open Reset Password Modal
  const handleResetPasswordModal = () => {
    setOpenResetPwModal(!openResetPwModal);
  };
  // Reset Password Function
  const handleResetPassword = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(auth, resetEmail);
    handleResetPasswordModal();
    setInfoModal("Email Send");
    handleInfoModal();
  };

  const handleInfoModal = () => {
    setOpenInfoModal(!openInfoModal);
  };

  return (
    <Page classname="sign-in">
      <section className="sign-in__container">
        <h2>Sign In</h2>
        <form className="sign-in__form" onSubmit={handleSignIn}>
          <Input
            id="email"
            type="Email"
            placeHolder="Type your Email"
            label="Email"
            onChange={setData}
          />
          <Input
            id="password"
            type="Password"
            placeHolder="Type your Password"
            label="Password"
            onChange={setData}
          />
          <button>Sign In</button>
        </form>
        <div className="sign-in__links">
          <Link to="/sign-up">Create an Account</Link>
          <button onClick={handleResetPasswordModal}>Forgot Password</button>
        </div>
      </section>

      <ModalResetPw
        isOpen={openResetPwModal}
        handleClose={handleResetPasswordModal}
        handleResetPassword={handleResetPassword}
        onChange={handleSetResetData}
      />
      <ModalInfo
        isOpen={openInfoModal}
        info={infoModal}
        handleClose={handleInfoModal}
      />
    </Page>
  );
};

const mapDispatchToProps = {
  signInUser,
};

export default connect(null, mapDispatchToProps)(SignIn);
