const AddWatermark = (image, watermark, watermarkSize = 540, watermarkText) => {
  const canvas = document.createElement("canvas");
  const imageWidth = image.width;
  const imageHeight = image.height;
  const watermarkMaxSize = watermarkSize;
  canvas.width = imageWidth;
  canvas.height = imageHeight;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, imageWidth, imageHeight);

  ctx.font = "60px sans-serif";
  ctx.fillStyle = "rgba(255,255,255, 0.25)";
  // // Up text
  ctx.fillText(watermarkText, 20, 60);
  ctx.strokeText(watermarkText, 20, 60);
  // Down Text
  ctx.textAlign = "right";
  ctx.fillText("ImagesPDF.com", imageWidth - 20, imageHeight - 20);
  ctx.strokeText("ImagesPDF.com", imageWidth - 20, imageHeight - 20);

  if (watermark) {
    ctx.globalAlpha = 0.1;
    ctx.drawImage(
      watermark,
      (imageWidth - watermarkMaxSize) / 2,
      (imageHeight - watermarkMaxSize) / 2,
      watermarkMaxSize,
      watermarkMaxSize
    );
  }

  const srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.75);
  return srcEncoded;
};

export default AddWatermark;
