/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import CompressImage from "../../functions/CompressImage";
import AddWatermark from "../../functions/AddWatermark";
import SaveFile from "../../functions/SaveFile";

import Page from "../../components/containers/Page";
import ImagesSheet from "../../components/templates/ImagesSheet";
import InputFile from "../../components/templates/InputFile";
import ModalLoader from "../../components/modal/ModalLoader";
import ModalSetting from "../../components/modal/ModalSetting";

const ImagesPDF = () => {
  const [images, setImages] = useState([]);
  const [imagesWatermark, setImagesWatermark] = useState([]);
  const [imagesFixed, setImagesFixed] = useState([]);
  const [watermark, setWatermark] = useState({
    src: null,
    img: null,
  });
  const [orderImages, setOrderImages] = useState(true);
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState(false);
  const [fileInfo, setFileInfo] = useState({
    name: "",
    watermarkText: "",
    length: 0,
  });
  const { name, watermarkText, length } = fileInfo;

  // Use Effect Inicial.
  useEffect(() => {
    getImagesInLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFileInfo({ ...fileInfo, length: images.length });
    addWatermark();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    fixImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesWatermark]);

  useEffect(() => {
    addWatermark();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watermark]);

  const handleInputImages = (event) => {
    const eventFiles = event.target.files;
    let files = [];
    let tempImages = [];

    // Validar si es una imagen y si no esta en el estado.
    // Si cumple las condiciones se agrega en el array files.
    for (let i = 0; i < eventFiles.length; i++) {
      const file = eventFiles[i];
      if (file.type !== "image/jpeg" && file.type !== "image/png") return null;
      const fileOnList = images.some((image) => {
        return image.name === file.name;
      });
      if (fileOnList) {
        return null;
      }
      files.push(file);
    }

    if (files) setLoading(true);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerE) => {
        const fullImg = new Image();
        fullImg.src = readerE.target.result;
        fullImg.onload = (fullImageE) => {
          const imageObject = CompressImage(fullImageE.target, file.name);
          tempImages.push(imageObject);
          if (tempImages.length === files.length) {
            if (orderImages) {
              const widthStateImage = images.filter((obj) => {
                return obj.width === 1080;
              });
              const widthReadImages = tempImages.filter((obj) => {
                return obj.width === 1080;
              });
              const widthImages = widthStateImage.concat(widthReadImages);
              //
              const heightStateImages = images.filter((obj) => {
                return obj.height === 1080;
              });
              const heightReadImages = tempImages.filter((obj) => {
                return obj.height === 1080;
              });
              const heightImages = heightStateImages.concat(heightReadImages);
              //
              const orderedImages = widthImages.concat(heightImages);
              setImages(orderedImages);
              saveImagesInLocalStorage(orderedImages);
            } else {
              const unorderedImages = images.concat(tempImages);
              setImages(unorderedImages);
              saveImagesInLocalStorage(unorderedImages);
            }
          }
        };
      };
    });
  };

  const handleInputWatermark = (event) => {
    const watermarkFile = event.target.files[0];
    if (watermarkFile.type !== "image/png") return null;

    const reader = new FileReader();
    reader.readAsDataURL(watermarkFile);
    reader.onload = (readerE) => {
      const fullImage = new Image();
      fullImage.src = readerE.target.result;
      fullImage.onload = (fullImageE) => {
        const imageObject = CompressImage(
          fullImageE.target,
          "watermark",
          540,
          "png"
        );
        const image = new Image();
        image.src = imageObject.src;
        image.onload = (imageE) => {
          setWatermark({
            src: imageObject.src,
            img: imageE.target,
          });
        };
      };
    };
  };

  const addWatermark = () => {
    let tempImages = [];
    images.forEach((imageObj) => {
      const image = new Image();
      image.src = imageObj.src;
      image.onload = (imageE) => {
        const waterImage = AddWatermark(
          imageE.target,
          watermark.img,
          540,
          watermarkText
        );
        const newImageObj = { ...imageObj, src: waterImage };
        tempImages.push(newImageObj);
        if (tempImages.length === images.length) {
          if (orderImages) {
            const widthImages = tempImages.filter((obj) => {
              return obj.width === 1080;
            });
            //
            const heightImages = tempImages.filter((obj) => {
              return obj.height === 1080;
            });
            //
            const orderedImages = widthImages.concat(heightImages);
            setImagesWatermark(orderedImages);
          } else {
            setImagesWatermark(tempImages);
          }
        }
      };
    });
  };

  const fixImages = () => {
    let currentHeight = 0;
    let tempArray = [];
    let finalArray = [];

    for (let i = 0; i < imagesWatermark.length; i++) {
      const imageObj = imagesWatermark[i];
      currentHeight += imageObj.height;
      if (currentHeight < 1754) {
        tempArray.push(imageObj);
        if (i === imagesWatermark.length - 1) {
          finalArray.push(tempArray);
        }
      } else {
        finalArray.push(tempArray);
        tempArray = [];
        tempArray.push(imageObj);
        currentHeight = imageObj.height;
        if (i === imagesWatermark.length - 1) {
          finalArray.push(tempArray);
        }
      }
    }
    setImagesFixed(finalArray);
    setLoading(false);
  };

  const handleDelete = (event) => {
    const name = event.target.id;
    const newRaw = images.filter((imageObject) => {
      return imageObject.name !== name;
    });
    setImages(newRaw);
    saveImagesInLocalStorage(newRaw);
  };

  const handleReset = () => {
    setImages([]);
    saveImagesInLocalStorage([]);
    setFileInfo({
      name: "",
      watermarkText: "",
      length: 0,
    });
  };

  const handleModalSetting = () => {
    setSetting(true);
  };

  const handleCloseSetting = () => {
    setSetting(false);
    addWatermark();
  };

  const handleChangeSetting = (event) => {
    setFileInfo({
      ...fileInfo,
      [event.target.id]: event.target.value,
    });
  };

  const saveImagesInLocalStorage = (imagesArray) => {
    localStorage.setItem("images", JSON.stringify(imagesArray));
  };
  const getImagesInLocalStorage = () => {
    if (localStorage.getItem("images")) {
      const imagesLocal = JSON.parse(localStorage.getItem("images"));
      setImages(imagesLocal);
    }
  };

  const saveFileAsPDF = () => {
    if (length > 0) {
      SaveFile(imagesFixed, name);
    } else {
      console.log("sin imagenes");
    }
  };

  return (
    <Page classname="images-pdf">
      <section className="images-pdf__header">
        <div className="images-pdf__header--info">
          <h2>{name ? name : "File name"}</h2>
          <p>
            {length}
            <i className="icon-images"></i>
          </p>
        </div>
        <hr />
        <div className="images-pdf__header--buttons">
          <button onClick={saveFileAsPDF}>
            <i className="icon-download"></i>
          </button>
          <button onClick={handleModalSetting}>
            <i className="icon-cog"></i>
          </button>
          <InputFile id="input-images" onInput={handleInputImages} />
        </div>
      </section>

      {length > 0 && (
        <section className="images-pdf__preview">
          {imagesFixed.map((imagesArray) => {
            const key = imagesFixed.indexOf(imagesArray);
            return (
              <ImagesSheet
                imagesInfo={imagesArray}
                handleDelete={handleDelete}
                key={key}
              />
            );
          })}
        </section>
      )}

      {!length && (
        <section className="images-pdf__empty">
          <div className="images-pdf__empty--card">
            <h3>You haven't uploaded any image</h3>
            <div className="input-file-empty">
              <label htmlFor="input-images-empty">
                Add Images<i className="icon-pictures"></i>
              </label>
              <input
                type="file"
                onInput={handleInputImages}
                name="input-images-empty"
                id="input-images-empty"
                multiple
              />
            </div>
          </div>
        </section>
      )}

      <section className="images-pdf__footer"></section>
      <ModalLoader isOpen={loading} />
      <ModalSetting
        isOpen={setting}
        handleClose={handleCloseSetting}
        fileInfo={fileInfo}
        handleChangeSetting={handleChangeSetting}
        handleReset={handleReset}
      />
    </Page>
  );
};

export default ImagesPDF;
