import React from "react";

const TextInput = ({
  id,
  label,
  placeHolder,
  type,
  onChange,
  value,
  maxLength,
}) => {
  return (
    <div className="Input">
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type}
        name={id}
        id={id}
        placeholder={placeHolder}
        onChange={onChange ? onChange : null}
        value={value}
        required
        maxLength={maxLength ? maxLength : null}
        autoComplete="off"
      />
    </div>
  );
};

export default TextInput;
