import React, { useState } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { signInUser } from "../redux/actions";
import { auth } from "../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

// Components
import Layout from "../components/layout/Layout";
import Loader from "../components/templates/Loader";

// Pages
import Home from "../pages/home/Home";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ImagesPDF from "../pages/app/ImagesPDF";
// import NotFound from "../pages/error/NotFound";

function App({ signInUser }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(false);

  onAuthStateChanged(auth, (fireUser) => {
    if (fireUser) {
      setLoading(false);
      signInUser();
      setUser(true);
    } else {
      setUser(false);
      setLoading(false);
    }
  });
  return (
    <>
      <Router>
        {!loading ? (
          <Layout>
            <>
              <Routes>
                {user ? (
                  <>
                    <Route path="/" element={<ImagesPDF />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </>
                ) : (
                  <>
                    <Route path="/" element={<Home />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </>
                )}
              </Routes>
            </>
          </Layout>
        ) : (
          <Loader />
        )}
      </Router>
    </>
  );
}

const mapDispatchToProps = {
  signInUser,
};

export default connect(null, mapDispatchToProps)(App);
