import React from "react";

const Loader = () => {
  return (
    <div className="loader">
      <p className="icon-load"></p>
    </div>
  );
};

export default Loader;
