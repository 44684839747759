import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { signOutUser } from "../../redux/actions";
import { auth } from "../../firebase/firebaseConfig";
import { signOut } from "firebase/auth";

import logo from "../../assets/images/Logo.svg";

const Header = ({ user, signOutUser }) => {
  const [activeUser, setActiveUser] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    handleRemoveMenu();
  }, []);

  useEffect(() => {
    setActiveUser(user.logged);
  }, [user]);

  const handleShowMenu = () => {
    window.scrollTo(0, 0);
    document.body.classList.toggle("menu");
    document.getElementById("header").classList.toggle("menu");
  };

  const handleRemoveMenu = () => {
    window.scrollTo(0, 0);
    document.body.classList.remove("menu");
  };

  // const handleGoBack = () => {
  //   navigate(-1);
  // };

  // eslint-disable-next-line no-unused-vars
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        signOutUser();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <header className="header" id="header">
      {/* {goBack && (
        <button className="header__back" onClick={handleGoBack}>
          <i className="icon-arrow-left"></i>
        </button>
      )} */}

      <div className="header__title">
        <Link to="/">
          <img src={logo} alt="ImagePDF logo" />
        </Link>
      </div>

      <button className="header__nav-button" onClick={handleShowMenu}>
        <div></div>
        <div></div>
        <div></div>
      </button>

      <nav className="header__nav-bar">
        <ul>
          {!activeUser ? (
            <>
              <li>
                <Link to="/sign-in">Sign In</Link>
              </li>
              <li>
                <Link to="/sign-up">Sign Up</Link>
              </li>
            </>
          ) : (
            <>
              <div className="User-menu">
                <div className="User-menu__icon">
                  <i className="icon-user"></i>
                </div>
                <ul>
                  <li>
                    <p>{user.email}</p>
                  </li>
                  <li>
                    <button onClick={handleSignOut}>Sign Out</button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  signOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
