const reducers = (state, action) => {
  switch (action.type) {
    case "SIGNIN_USER":
      return {
        ...state,
        user: {
          logged: true,
          email: action.payload,
        },
      };

    case "SIGNOUT_USER":
      return {
        ...state,
        user: {
          logged: false,
          email: null,
        },
      };

    default:
      return state;
  }
};

export default reducers;
