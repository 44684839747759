import React from "react";

const InputFile = ({ id, onInput }) => {
  return (
    <div className="input-file">
      <label htmlFor={id}>
        <i className="icon-pictures"></i>
      </label>
      <input
        type="file"
        onInput={onInput}
        name={id}
        id={id}
        multiple="multiple"
      />
    </div>
  );
};

export default InputFile;
