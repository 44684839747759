//React & Redux
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import reducer from "./redux/reducers";

// App & Styles
import App from "./app/App";
import "./assets/styles/Styles.scss";

// Redux Config
const initialState = {
  user: {
    logged: false,
    email: null,
  },
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancers());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
