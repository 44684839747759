import React from "react";

import Modal from "./Modal";
import Input from "../templates/Input";

const ModalSetting = ({
  isOpen,
  handleClose,
  fileInfo,
  handleChangeSetting,
  handleReset,
  handleInputWatermark,
  handleDeleteWatermark,
  watermark,
}) => {
  const { name, watermarkText } = fileInfo;

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <main className="modal-setting">
        <h2 className="modal-setting__title">
          <i className="icon-cog"></i>Settings
        </h2>
        <section className="modal-setting__input">
          <Input
            id="name"
            label="Name"
            placeHolder="File name"
            onChange={handleChangeSetting}
            value={name}
            maxLength={15}
          />
          <Input
            id="watermarkText"
            label="Watermark text"
            placeHolder="Watermark text"
            onChange={handleChangeSetting}
            value={watermarkText}
            maxLength={10}
          />
          <div className="modal-setting__input--watermark">
            <p>Watermark Image</p>
            <label htmlFor="watermark-image">
              <i className="icon-image"></i>
            </label>
            <input type="file" name="watermark-image" id="watermark-image" />
          </div>
        </section>

        <section className="modal-setting__reset">
          <h3>Reset Project</h3>
          <p>
            Delete all the images and set the project by default. There's no way
            back, are you sure?
          </p>
          <button onClick={handleReset}>Reset</button>
        </section>

        <section className="modal-setting__close">
          <button onClick={handleClose}>Save</button>
        </section>
      </main>
      {/* <section className="settings-modal">
        <Input
          id="name"
          label="Name"
          placeHolder="File name"
          onChange={handleChangeSettings}
          value={name}
          maxLength={15}
        />

        <div className="settings-modal__watermark">
          <h3>Watermark</h3>
          {!watermark.img ? (
            <div className="settings-modal__watermark--input">
              <label htmlFor="watermark">
                <i className="icon-image"></i>
              </label>
              <input
                type="file"
                name="watermark"
                id="watermark"
                onInput={handleInputWatermark}
              />
            </div>
          ) : (
            <div className="settings-modal__watermark--preview">
              <figure className="settings-modal__watermark--preview">
                <img src={watermark.src} alt="watermark" />
              </figure>
              <button onClick={handleDeleteWatermark}>Delete</button>
            </div>
          )}
        </div>

        <div className="settings-modal__reset">
          <button onClick={handleDeleteAll}>Reset Project</button>
        </div>
      </section> */}
      <section></section>
    </Modal>
  );
};

export default ModalSetting;
