import React from "react";

import Modal from "./Modal";
import TextInput from "../templates/Input";
const ModalResetPw = ({
  isOpen,
  handleClose,
  handleResetPassword,
  onChange,
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <form className="Reset-password-modal" onSubmit={handleResetPassword}>
        <h2>Reset Password</h2>
        <TextInput
          id={"email"}
          label={"Email"}
          placeHolder={"Type your Email"}
          type={"email"}
          onChange={onChange}
        />
        <div className="Rechaptcha"></div>
        <button>Reset Password</button>
      </form>
    </Modal>
  );
};

export default ModalResetPw;
