import React from "react";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__slogan">
        <h2>Images PDF</h2>
        {/* <p>The images PDF maker for photographers.</p> */}
        <p>The perfect PDF maker for photographers.</p>
      </div>
      <div className="Footer__links">
        <div className="Footer__links--social-media">
          <h3>Find me on:</h3>
          <ul>
            <li>
              <a
                href="https://github.com/neriobalza"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-github"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/neriobalza/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/neriobalza_"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
        <hr />
        <div className="Footer__links--more-links">
          <h3>More links:</h3>
          <p>...</p>
        </div>
      </div>
      <div className="Footer__copyright">
        <p>
          Made by{" "}
          <a href="https://neriobalza.com" target="_blank" rel="noreferrer">
            @neriobalza
          </a>{" "}
          - © 2022
        </p>
      </div>
    </footer>
  );
};

export default Footer;
