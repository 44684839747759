import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";

const ImagesSheet = ({ imagesInfo, handleDelete }) => {
  useEffect(() => {
    ScrollReveal().reveal(".image-sheet", {
      duration: 500,
      delay: 200,
      origin: "bottom",
      scale: 0.75,
    });
  }, []);

  return (
    <div className="image-sheet">
      {imagesInfo.map((image) => {
        const { name, src } = image;
        let imageClass = "Width";
        if (image.height > image.width) imageClass = "Height";

        return (
          <figure className={`image-sheet__container ${imageClass}`} key={name}>
            <img src={src} alt={name} className={imageClass} />
            <div className="image-sheet__buttons">
              <button>
                <i className="icon-bin" onClick={handleDelete} id={name}></i>
              </button>
            </div>
          </figure>
        );
      })}
    </div>
  );
};

export default ImagesSheet;
