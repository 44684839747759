import React from "react";
import Modal from "./Modal";
const ModalInfo = ({ isOpen, info, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div className="Info-modal">
        <h2>{info}</h2>
        <button onClick={handleClose}>Close</button>
      </div>
    </Modal>
  );
};

export default ModalInfo;
