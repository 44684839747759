import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Components
import Page from "../../components/containers/Page";

const Home = () => {
  return (
    <Page classname="home">
      <section className="home__hero">
        <h1 className="home__hero--title">The images PDF maker.</h1>
        <p className="home__hero--description">
          Upload your images and create a PDF that you can deliver to your
          customers.
        </p>
        <p className="home__hero--links">
          <Link to="/sign-in">Sign in</Link>
          Or
          <Link to="/sign-up">Sign up</Link>
        </p>
      </section>

      <section className="home__about">
        <article className="home__about--article">
          <h3>Easy to use</h3>
          <p>
            Just upload your images, set the name, orientation and fit, and then
            save it. You won't get lost using this app.
          </p>
        </article>

        <article className="home__about--article">
          <h3>Fast</h3>
          <p>
            Upload as many images as you want, and save as many files as you
            need. It does not matter if they are a thousand.
          </p>
        </article>

        <article className="home__about--article">
          <h3>Customizable</h3>
          <p>
            Set the file name, the file orientation and the image fit to save
            your file as you want it.
          </p>
        </article>
      </section>
      <section className="home__tutorial">
        <h2>How to use</h2>
        <p>1 Add your images</p>
        <p>2 Set and custom your project</p>
        <p>3 Save your PDF</p>
      </section>
    </Page>
  );
};

// const maptStateToProps = {};
// const mapDispathToProps = {}

export default connect(null, null)(Home);
